import { useFormik } from "formik"
import React, { useEffect } from "react"
import { useState } from "react"
import { getInitialValues } from "../../../extensions/FormikExtension"
import {
  ZoneName,
  managementSchema,
} from "../../../validationSchema/ManagementSchema"
import ManagementApi from "./../../../Api/Managementapi"
import { ToastTemplate, ToastType } from "../../../notifications/ToastExecutor"
import LoadingScreen from "../../../Component/LoadingScreen/LoadingScreen"

export default function ZoneModal(props) {
  const [loading, setLoading] = useState(false)

  async function addZone() {
    let body = {
      name: formik.getFieldProps(ZoneName.Name).value,
    }
    setLoading(true)

    try {
      let res = await ManagementApi.zoneApi.addZone.call(body)

      if (res.status) {
        new ToastTemplate(ToastType.Success, res.message).execute()

        setLoading(false)
        props.refreshZoneList()
      } else {
        new ToastTemplate(ToastType.Error, res.message).execute()

        setLoading(false)
      }
    } catch (err) {
      new ToastTemplate(ToastType.Error, err.message).execute()

      setLoading(false)
    }
  }

  async function handleZone(data) {
    formik.setFieldValue(ZoneName.Name, data?.name || "")
  }

  useEffect(() => {
    if (props.mode === "edit") {
      handleZone(props?.data)
    }
  }, [props])

  async function editZone() {
    try {
      let body = {
        name: formik.getFieldProps(ZoneName.Name).value,
        id: props.data.id,
      }

      setLoading(true)
      let res = await ManagementApi.zoneApi.updateZone.call(body)

      if (res.status) {
        new ToastTemplate(ToastType.Success, res.message).execute()

        props.refreshZoneList()
        setLoading(false)
      } else {
        new ToastTemplate(ToastType.Error, res.message).execute()

        setLoading(false)
      }
    } catch (err) {
      new ToastTemplate(ToastType.Error, err.message).execute()

      setLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: getInitialValues([ZoneName]),
    validationSchema: managementSchema,
    onSubmit: async () => {
      if (props?.mode === "edit") {
        editZone()
      } else {
        addZone()
      }
      formik.resetForm()
    },
  })

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header  border-0">
              <h1
                className="modal-title fs-5 title-add-madal"
                id="exampleModalLabel"
              >
                {props.mode === "add" ? "Add zone" : "Edit zone"}
              </h1>
              <button
                type="button"
                className="btn-close"
                style={{ top: "18px", position: "absolute", right: "15px" }}
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {}}
              ></button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  formik.handleSubmit()
                }}
                className="d-flex align-items-center flex-column"
              >
                <div className="field-group">
                  <label
                    htmlFor="ZoneName"
                    className="d-block ModelNumber-label"
                  >
                    Zone name
                  </label>
                  <input
                    className={
                      formik.getFieldMeta(ZoneName.Name).touched &&
                      formik.getFieldMeta(ZoneName.Name).error != null
                        ? "form-control input-error error-margin Model-Number"
                        : "form-control Model-Number"
                    }
                    type="text"
                    id={ZoneName.Name}
                    name="ZoneName"
                    {...formik.getFieldProps(ZoneName.Name)}
                  />
                  {formik.getFieldMeta(ZoneName.Name).touched &&
                  formik.getFieldMeta(ZoneName.Name).error != null ? (
                    <div className="error">
                      {formik.getFieldMeta(ZoneName.Name).error}
                    </div>
                  ) : null}
                </div>
                <div className="modal-footer border-0 d-flex justify-content-center">
                  <button
                    type="submit"
                    className={
                      !(formik.dirty && formik.isValid)
                        ? "disabled-btn"
                        : "add-modal-btn"
                    }
                    data-bs-dismiss="modal"
                    disabled={!(formik.dirty && formik.isValid)}
                  >
                    {props.mode === "add" ? "Add " : "Apply changes"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
