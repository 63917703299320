import React, { useEffect, useMemo, useState } from "react"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from "jquery"
import { createRoot } from "react-dom/client"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"

import { FiEdit } from "react-icons/fi"
import deleteIcon from "../../../Assets/icon/Delete.svg"

import ManagementApi from "./../../../Api/Managementapi"
import LoadingScreen from "../../../Component/LoadingScreen/LoadingScreen"

import { getInitialValues } from "../../../extensions/FormikExtension"
import { Formik, Field, Form, useFormik } from "formik"
import {
  availableZones,
  shiftEndTime,
  accessLevelName,
  selectedDays,
  AccessLevelSchema,
  shiftStartTime,
} from "./../../../validationSchema/AccessLevelSchema"

import {
  ToastTemplate,
  ToastType,
} from "./../../../notifications/ToastExecutor"

export default function AccessLevels() {
  const dayList = {
    SUN: {
      name: "SUN",
      value: 1,
      isSelected: false,
    },
    MON: {
      name: "MON",
      value: 2,
      isSelected: false,
    },
    TUS: {
      name: "TUS",
      value: 4,
      isSelected: false,
    },
    WED: {
      name: "WED",
      value: 8,
      isSelected: false,
    },
    THU: {
      name: "THU",
      value: 16,
      isSelected: false,
    },

    FRI: {
      name: "FRI",
      value: 32,
      isSelected: false,
    },
    SAT: {
      name: "SAT",
      value: 64,
      isSelected: false,
    },
  }
  const [id, setId] = useState("")
  const [accessLevelList, setAccessLevelList] = useState(
    ManagementApi.zoneApi.zoneList.default.data
  )
  const [zoneList, setZoneList] = useState(
    ManagementApi.zoneApi.zoneList.default.data
  )

  // loading state
  const [loading, setLoading] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [accessLevelBody, setAccessLevelBody] = useState({})

  const MemoisedData = useMemo(() => {
    fillAccessLevel()
    fillZones()
  }, [])

  const [initialValues, setInitialValues] = useState(
    getInitialValues([
      accessLevelName,
      shiftStartTime,
      shiftEndTime,
      availableZones,
      selectedDays,
    ])
  )

  async function fillAccessLevel() {
    try {
      setLoading(true)
      let res = await ManagementApi.accessLevelsApi.accessLevelList.call()

      if (res.status) {
        setAccessLevelList(res.data)
        setLoading(false)

        return res.data
      } else {
        new ToastTemplate(ToastType.Error, res.message).execute()
        setLoading(false)
      }
    } catch (err) {
      new ToastTemplate(ToastType.Error, err.message).execute()
      setLoading(false)
    }
  }

  async function addAccessLevel(accessLevelBody) {
    setLoading(true)

    try {
      let res = await ManagementApi.accessLevelsApi.addAccessLevel.call(
        accessLevelBody
      )
      if (res.status) {
        new ToastTemplate(ToastType.Success, res.message).execute()

        setLoading(false)
        fillAccessLevel()
      } else {
        new ToastTemplate(ToastType.Error, res.message).execute()

        setLoading(false)
      }
    } catch (err) {
      new ToastTemplate(ToastType.Success, err.message).execute()

      setLoading(false)
    }
  }

  function resetFields() {
    setInitialValues({
      accessLevelName: "",
      shiftStartTime: "",
      shiftEndTime: "",
      availableZones: "",
      selectedDays: [],
    })
  }

  const convertToTimeString = (time) => {
    // Convert time from "hh:mm tt" to "hh:mm" format
    const timeParts = time.split(":")
    const hours = parseInt(timeParts[0])
    const minutes = parseInt(timeParts[1].split(" ")[0])
    const isPM = timeParts[1].split(" ")[1] === "PM"

    let convertedHours = hours
    if (isPM && hours !== 12) {
      convertedHours += 12
    } else if (!isPM && hours === 12) {
      convertedHours = 0
    }

    const formattedTime = `${convertedHours
      .toString()
      .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`
    return formattedTime
  }

  async function handleAccessLevel(data) {
    var formatDate = {
      start: "",
      end: "",
    }

    formatDate.start = convertToTimeString(data.shiftStartTime)
    formatDate.end = convertToTimeString(data.shiftEndTime)

    // if (data.shiftStartTime.includes("PM")) {
    //   formatDate.start = convertToTimeString(data.shiftStartTime);
    // } else if (data.shiftStartTime.includes("AM")) {
    //   formatDate.start = data.shiftStartTime.replace("AM", "");
    // }
    // if (data.shiftEndTime.includes("PM")) {
    //   formatDate.end = data.shiftEndTime.replace("PM", "");
    // } else if (data.shiftEndTime.includes("AM")) {
    //   formatDate.end = data.shiftEndTime.replace("AM", "");
    // }

    let selectedDays = []

    Object.values(dayList).forEach((day) => {
      if ((data.days & day.value) != 0) {
        selectedDays.push(day.value.toString())
      }
    })

    setAccessLevelBody(data)

    setInitialValues({
      accessLevelName: data.name,
      shiftStartTime: formatDate.start.trim(),
      shiftEndTime: formatDate.end.trim(),
      availableZones: data.zoneIds[0],
      selectedDays: selectedDays,
    })
  }

  async function fillZones() {
    try {
      setLoading(true)
      let res = await ManagementApi.zoneApi.zoneList.call()

      if (res.status) {
        setZoneList(res.data)

        setLoading(false)
      } else {
        new ToastTemplate(ToastType.Error, res.message).execute()

        setLoading(false)
      }
    } catch (err) {
      new ToastTemplate(ToastType.Error, err.message).execute()

      setLoading(false)
    }
  }

  async function editAccessLevel(accessLevelBody) {
    try {
      setLoading(true)
      let res = await ManagementApi.accessLevelsApi.updateAccessLevel.call(
        accessLevelBody
      )

      if (res.status) {
        new ToastTemplate(ToastType.Success, res.message).execute()

        fillAccessLevel()
        setLoading(false)
      } else {
        new ToastTemplate(ToastType.Error, res.message).execute()
        setLoading(false)
      }
    } catch (err) {
      new ToastTemplate(ToastType.Error, err.message).execute()

      setLoading(false)
    }
  }

  async function deleteAccessLevel(id) {
    try {
      setLoading(true)
      let res = await ManagementApi.accessLevelsApi.deleteAccessLevel.call(id)

      if (res.status) {
        new ToastTemplate(ToastType.Success, res.message).execute()

        setLoading(false)
        fillAccessLevel()
      } else {
        new ToastTemplate(ToastType.Error, res.message).execute()

        setLoading(false)
      }
    } catch (err) {
      new ToastTemplate(ToastType.Error, err.message).execute()

      setLoading(false)
    }
  }

  useEffect(() => {
    $("#table").DataTable({
      stateSave: false,
      bDestroy: true,
      language: {
        paginate: {
          previous: "<",
          next: ">",
        },
      },
      lengthMenu: [
        [5, 10, 15, -1],
        [5, 10, 15, "Show all"],
      ],
      data: accessLevelList,
      columns: [
        {
          title: `Access Level`,
          data: "name",
          searchable: true,
          orderable: true,
          createdCell: function (td, cellData, rowData, row, col, tr) {
            $(td).addClass("col-edit")
          },
        },
        {
          title: `Zone`,
          data: "zones[0].name",
          searchable: true,
          orderable: true,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit")
          },
        },
        {
          title: `Shift Start Time`,
          data: "shiftStartTime",
          searchable: true,
          orderable: true,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit")
          },
        },

        {
          title: `Shift End Time`,
          data: "shiftEndTime",
          searchable: true,
          orderable: true,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit")
          },
        },

        {
          title: `Days`,
          data: "daysString",
          searchable: true,
          orderable: true,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit")
          },
        },

        {
          title: ``,
          orderable: false,
        },
      ],
      dom:
        "<'row  '<'col-sm-12 col-md-6 d-flex mt-3'f><'col-sm-6  flex-row-reverse col-md-6 d-flex flex-row-reverse mb-5'B>>" +
        "t<'row  e_table'<'col-sm-12 col-md-6 d-flex align-items-end justify-content-end 'p><'col-sm-6  flex-row-reverse col-md-6 d-flex flex-row-reverse   mt-3'l>>",
      columnDefs: [
        {
          target: -1,
          createdCell: (td, cellData, rowData, row, col) => {
            createRoot(td).render(
              <div className="d-flex align-items-center justify-content-around">
                <button
                  className="deleteBtn "
                  data-bs-toggle="modal"
                  data-bs-target="#deleteModal"
                  onClick={() => {
                    setId(rowData.id)
                  }}
                >
                  <img src={deleteIcon} alt="" />
                </button>
                <button
                  className="deleteBtn "
                  data-bs-toggle="modal"
                  data-bs-target="#editLevelModal"
                  onClick={() => {
                    setEditMode(true)
                    handleAccessLevel(rowData)
                  }}
                >
                  <FiEdit />
                </button>
              </div>
            )
          },
        },
        {
          defaultContent: "-",
          targets: ["_all"],
          className: "dt-body-left",
        },
      ],
      buttons: [
        {
          text: '<i class="fa-solid fa-rotate-right fs-4"></i>',
          className: "re-btn",
          action: function (e, dt, node, config) {
            fillAccessLevel()
          },
        },
      ],
      initComplete: function () {
        $("#table thead tr")
          .removeClass("col-edit")
          .addClass("filters")
          .appendTo("#table thead")

        var api = this.api()
        // For each column
        api
          .columns()
          .eq(0)
          .each(function (colIdx) {
            var column = api.settings().init().columns[colIdx]
            var cell = $(".filters th").eq(
              $(api.column(colIdx).header()).index()
            )
            var title = column.title
            if (!column.searchable) {
              $(cell).html(`
              <div style="display: flex; align-items: center;">
                <span class="col-edit">${title}</span>
              </div>
            `)
            } else {
              // If the column is searchable, add the search input
              // Set the header cell to contain the input element
              $(cell).html(`
            <div style="display: flex; align-items: center;">
              <span class="col-edit">${title}</span>
              <img src="/search.svg" alt="search" style="cursor: pointer; margin-left: auto;" />
            </div>
            <div class="hide clear-input-container" >
              <input type="text" placeholder="search" class="clear-input" />
              <button class="clear-input-button">X</button>
            </div>
            `)

              // On clicking the search icon, toggle the visibility of the input element
              $("img", cell).on("click", function (e) {
                e.preventDefault()
                e.stopPropagation()
                $(".clear-input-container", cell).toggleClass("hide")
              })

              $("input", cell).on("input", function (e) {
                const input = $(this)
                if (input.val() && !input.hasClass("clear-input--touched")) {
                  input.addClass("clear-input--touched")
                } else if (
                  !input.val() &&
                  input.hasClass("clear-input--touched")
                ) {
                  input.removeClass("clear-input--touched")
                }
              })
              $("button", cell).on("click", function (e) {
                e.stopPropagation()
                const input = $(this).siblings(".clear-input")
                input.val("")
                input.removeClass("clear-input--touched")
                input.trigger("change")
                $(".clear-input-container", cell).toggleClass("hide")
              })
              // On every keypress in this input
              $("input", cell)
                .off("keyup change")
                .on("change", function (e) {
                  // Get the search value
                  $(this).attr("title", $(this).val())
                  api.columns(colIdx).search(this.value).draw()
                })
                .on("keydown", function (e) {
                  e.stopPropagation()
                })
                .on("keyup", function (e) {
                  e.stopPropagation()
                  $(this).trigger("change")
                })
                .on("click", function (e) {
                  e.preventDefault() // Prevent the default behavior (sorting action)
                  e.stopPropagation() // Stop event propagation to avoid unintended side effects
                })
            }
          })
      },
    })
  }, [accessLevelList])

  const formik = useFormik({
    initialValues: getInitialValues([
      availableZones,
      shiftEndTime,
      accessLevelName,
      selectedDays,
      AccessLevelSchema,
      shiftStartTime,
    ]),
  })

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="card-table">
          <table
            id="table"
            className="display   row-border "
            style={{ width: "100%", marginTop: "40px" }}
          ></table>
        </div>
      )}

      <div
        className="modal fade"
        id="createLevelModal"
        tabIndex="-1"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header  border-0">
              <h1
                className="modal-title fs-5 title-add-madal"
                id="exampleModalLabel"
              >
                Create access level
              </h1>
              <button
                type="button"
                className="btn-close"
                style={{ top: "18px", position: "absolute", right: "15px" }}
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  resetFields()
                }}
              ></button>
            </div>
            <div className="modal-body">
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={AccessLevelSchema}
                onSubmit={(values) => {
                  let petDay = values.selectedDays.map((int) => {
                    return parseInt(int)
                  })

                  var totalDayCount = 0
                  petDay.forEach((day) => {
                    if (day) {
                      totalDayCount = totalDayCount | day
                    }
                  })

                  if (editMode) {
                    let editBody = {
                      name: values.accessLevelName,
                      shiftStartTime: values.shiftStartTime,
                      shiftEndTime: values.shiftEndTime,
                      days: totalDayCount,
                      zoneIds: [values.availableZones],
                      id: accessLevelBody.id,
                    }

                    editAccessLevel(editBody)
                  } else {
                    let addBody = {
                      name: values.accessLevelName,
                      shiftStartTime: values.shiftStartTime,
                      shiftEndTime: values.shiftEndTime,
                      days: totalDayCount,
                      zoneIds: [values.availableZones],
                    }
                    addAccessLevel(addBody)
                  }

                  resetFields()
                }}
              >
                {({ handleChange, touched, errors, dirty, isValid }) => (
                  <Form>
                    <div className="field-group mb-2">
                      <label htmlFor="accessLevelName">Access level name</label>
                      <Field
                        className={
                          touched.accessLevelName &&
                          errors.accessLevelName != null
                            ? "form-control input-error error-margin "
                            : "form-control "
                        }
                        type="text"
                        name="accessLevelName"
                        id={accessLevelName.Name}
                      />
                      {touched.accessLevelName &&
                      errors.accessLevelName != null ? (
                        <div className="error">{errors.accessLevelName}</div>
                      ) : null}
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-6">
                        <div className="field-group">
                          <label htmlFor="shiftStartTime">
                            Shift start time
                          </label>
                          <Field
                            className={
                              touched.shiftStartTime &&
                              errors.shiftStartTime != null
                                ? "form-control input-error error-margin "
                                : "form-control "
                            }
                            type="time"
                            name="shiftStartTime"
                            id={shiftStartTime.Name}
                          />
                          {touched.shiftStartTime &&
                          errors.shiftStartTime != null ? (
                            <div className="error">{errors.shiftStartTime}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="field-group">
                          <label htmlFor="shiftEndTime">Shift end time</label>
                          <Field
                            className={
                              touched.shiftEndTime &&
                              errors.shiftEndTime != null
                                ? "form-control input-error error-margin "
                                : "form-control "
                            }
                            type="time"
                            name="shiftEndTime"
                            id={shiftEndTime.Name}
                          />
                          {touched.shiftEndTime &&
                          errors.shiftEndTime != null ? (
                            <div className="error">{errors.shiftEndTime}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="field-group mb-2">
                      <label htmlFor="availableZones">Available Zones</label>
                      <Field
                        as="select"
                        className={
                          touched.availableZones &&
                          errors.availableZones != null
                            ? "form-select input-error error-margin "
                            : "form-select "
                        }
                        name="availableZones"
                        id={availableZones.Name}
                      >
                        <option value="">Select zone</option>
                        {zoneList.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          )
                        })}
                      </Field>
                      {touched.availableZones &&
                      errors.availableZones != null ? (
                        <div className="error">{errors.availableZones}</div>
                      ) : null}
                    </div>

                    <div className="row my-3 mx-4">
                      {Object.values(dayList).map((day) => {
                        return (
                          <div className="col-md-3" key={day.name}>
                            <div className="form-check">
                              <Field
                                className={
                                  touched.selectedDays &&
                                  errors.selectedDays != null
                                    ? "form-check-input input-error error-margin "
                                    : "form-check-input "
                                }
                                onChange={(e) => {
                                  handleChange(e)
                                }}
                                value={day.value.toString()}
                                name={selectedDays.Name}
                                type="checkbox"
                              />

                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                              >
                                {day.name}
                              </label>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    {touched.selectedDays && errors.selectedDays != null ? (
                      <div className="error">{errors.selectedDays}</div>
                    ) : null}

                    <div className="modal-footer border-0 d-flex justify-content-center">
                      <button
                        type="submit"
                        className={
                          !(dirty && isValid) ? "disabled-btn" : "add-modal-btn"
                        }
                        data-bs-dismiss="modal"
                        disabled={!(dirty && isValid)}
                      >
                        Add
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        data-bs-backdrop="static"
        id="editLevelModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header  border-0">
              <h1
                className="modal-title fs-5 title-add-madal"
                id="exampleModalLabel"
              >
                Edit access level
              </h1>
              <button
                type="button"
                className="btn-close"
                style={{ top: "18px", position: "absolute", right: "15px" }}
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  resetFields()
                }}
              ></button>
            </div>
            <div className="modal-body">
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={AccessLevelSchema}
                onSubmit={(values) => {
                  let petDay = values.selectedDays.map((int) => {
                    return parseInt(int)
                  })

                  var totalDayCount = 0
                  petDay.forEach((day) => {
                    if (day) {
                      totalDayCount = totalDayCount | day
                    }
                  })

                  if (editMode) {
                    let editBody = {
                      name: values.accessLevelName,
                      shiftStartTime: values.shiftStartTime,
                      shiftEndTime: values.shiftEndTime,
                      days: totalDayCount,
                      zoneIds: [values.availableZones],
                      id: accessLevelBody.id,
                    }

                    editAccessLevel(editBody)
                  } else {
                    let addBody = {
                      name: values.accessLevelName,
                      shiftStartTime: values.shiftStartTime,
                      shiftEndTime: values.shiftEndTime,
                      days: totalDayCount,
                      zoneIds: [values.availableZones],
                    }
                    addAccessLevel(addBody)
                  }
                  resetFields()
                }}
              >
                {({ handleChange, touched, errors, dirty, isValid }) => (
                  <Form>
                    <div className="field-group mb-2">
                      <label htmlFor="accessLevelName">Access level name</label>
                      <Field
                        className={
                          touched.accessLevelName &&
                          errors.accessLevelName != null
                            ? "form-control input-error error-margin "
                            : "form-control "
                        }
                        type="text"
                        name="accessLevelName"
                        id={accessLevelName.Name}
                      />
                      {touched.accessLevelName &&
                      errors.accessLevelName != null ? (
                        <div className="error">{errors.accessLevelName}</div>
                      ) : null}
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-6">
                        <div className="field-group">
                          <label htmlFor="shiftStartTime">
                            Shift start time
                          </label>
                          <Field
                            className={
                              touched.shiftStartTime &&
                              errors.shiftStartTime != null
                                ? "form-control input-error error-margin "
                                : "form-control "
                            }
                            type="time"
                            name="shiftStartTime"
                            id={shiftStartTime.Name}
                          />
                          {touched.shiftStartTime &&
                          errors.shiftStartTime != null ? (
                            <div className="error">{errors.shiftStartTime}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="field-group">
                          <label htmlFor="shiftEndTime">Shift end time</label>
                          <Field
                            className={
                              touched.shiftEndTime &&
                              errors.shiftEndTime != null
                                ? "form-control input-error error-margin "
                                : "form-control "
                            }
                            type="time"
                            name="shiftEndTime"
                            id={shiftEndTime.Name}
                          />
                          {touched.shiftEndTime &&
                          errors.shiftEndTime != null ? (
                            <div className="error">{errors.shiftEndTime}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="field-group mb-2">
                      <label htmlFor="availableZones">Available Zones</label>
                      <Field
                        as="select"
                        className={
                          touched.availableZones &&
                          errors.availableZones != null
                            ? "form-select input-error error-margin "
                            : "form-select "
                        }
                        name="availableZones"
                        id={availableZones.Name}
                      >
                        <option value="">Select zone</option>
                        {zoneList.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          )
                        })}
                      </Field>
                      {touched.availableZones &&
                      errors.availableZones != null ? (
                        <div className="error">{errors.availableZones}</div>
                      ) : null}
                    </div>

                    <div className="row my-3 mx-4">
                      {Object.values(dayList).map((day) => {
                        return (
                          <div className="col-md-3" key={day.name}>
                            <div className="form-check">
                              <Field
                                className={
                                  touched.selectedDays &&
                                  errors.selectedDays != null
                                    ? "form-check-input input-error error-margin "
                                    : "form-check-input "
                                }
                                onChange={(e) => {
                                  handleChange(e)
                                }}
                                value={day.value.toString()}
                                name={selectedDays.Name}
                                type="checkbox"
                              />

                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                              >
                                {day.name}
                              </label>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    {touched.selectedDays && errors.selectedDays != null ? (
                      <div className="error">{errors.selectedDays}</div>
                    ) : null}

                    <div className="modal-footer border-0 d-flex justify-content-center">
                      <button
                        type="submit"
                        className={
                          !(dirty && isValid) ? "disabled-btn" : "add-modal-btn"
                        }
                        data-bs-dismiss="modal"
                        disabled={!(dirty && isValid)}
                      >
                        Apply changes
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body d-flex align-items-center justify-content-center">
              <h1>Are you sure you want to delete?</h1>
            </div>
            <div className="modal-footer border-0 d-flex justify-content-center">
              <button
                type="submit"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => {
                  deleteAccessLevel(id)
                }}
              >
                YES
              </button>
              <button
                type="submit"
                className="btn  btn-success"
                data-bs-dismiss="modal"
              >
                NO
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
