import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { LoginApi, requireAdminRole, requireOperatorRole } from "./Api/LoginApi"
import Login from "./Page/Login/Login"
import Home from "./Page/Home/Home"
import Navbar from "./Component/Navbar/Navbar"
import Management from "./Page/Management/Management"
import ImportPage from "./Page/Import/ImportPage"
import Reports from "./Page/Reports/Reports"
import NewPassword from "./Page/Login/NewPassword"
import ForgotPassword from "./Page/Login/ForgotPassword"
import PageNotFound from "./Page/PageNotFound"
import { ToastContainer } from "react-toastify"

function App() {
  const location = useLocation()

  function ProtectedRoute({ children }) {
    if (!(location.pathname == "/" || location.pathname.startsWith("/login"))) {
      let token = LoginApi.getToken()
      if (token) {
        const storedRole = LoginApi.getUserRole()
        LoginApi.DecodeToken.call(token)
          .then((res) => {
            const decodedRole = res.data.role
            if (storedRole !== decodedRole) {
              // Redirect to unauthorized page if roles don't match
              LoginApi.navigateToUnauthorizedPage()
            }
          })
          .catch((res) => {
            if (res.code == 401)
              // unauthroized
              LoginApi.navigateToUnauthorizedPage()
            else {
            }
          })
      } else {
        LoginApi.navigateToLoginPage()
      }
    }

    return children
  }

  return (
    <>
      <ToastContainer />
      {location.pathname === "/" || location.pathname.startsWith("/login") ? (
        ""
      ) : LoginApi.getToken() ? (
        <Navbar />
      ) : (
        ""
      )}

      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login/unauthorized" element={<Login />} />
        <Route path="/login/forgot-password" element={<ForgotPassword />} />
        <Route path="/login/reset-password" element={<NewPassword />} />

        <Route
          path="*"
          element={
            <ProtectedRoute>
              <PageNotFound />
            </ProtectedRoute>
          }
        />
        <Route path="/home" element={<Home />} />
        <Route
          path="/reports"
          element={
            <ProtectedRoute>
              <Reports />
            </ProtectedRoute>
          }
        />

        <Route
          path="/import"
          element={
            <ProtectedRoute>
              {requireOperatorRole() ? (
                <ImportPage />
              ) : (
                <Navigate to="/login/unauthorized" />
              )}
            </ProtectedRoute>
          }
        />
        {/* <Route path="/export" element={<ProtectedRoute >{requireOperatorRole() ? <ExportPage /> : <Navigate to="/login/unauthorized" />}</ProtectedRoute>} /> */}
        <Route
          path="/management"
          element={
            <ProtectedRoute>
              {requireAdminRole() ? (
                <Management />
              ) : (
                <Navigate to="/login/unauthorized" />
              )}
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  )
}

export default App
