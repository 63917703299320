import { Field, Form, Formik } from "formik"
import React, { useMemo } from "react"
import { getInitialValues } from "../../../extensions/FormikExtension"
import {
  GroupSchema,
  groupNameField,
  groupAccessLevel,
} from "../../../validationSchema/GroupSchema"
import { useState } from "react"
import { useEffect } from "react"
import ManagementApi from "../../../Api/Managementapi"
import {
  ToastTemplate,
  ToastType,
} from "./../../../notifications/ToastExecutor"
import LoadingScreen from "../../../Component/LoadingScreen/LoadingScreen"

export default function GroupModal(props) {
  const [loading, setLoading] = useState(false)

  const [accessLevelList, setAccessLevelList] = useState(
    ManagementApi.accessLevelsApi.accessLevelList.default.data
  )
  const [initialValues, setInitialValues] = useState(
    getInitialValues([groupNameField, groupAccessLevel])
  )
  async function addGroup(body) {
    setLoading(true)

    try {
      let res = await ManagementApi.groupApi.addGroup.call(body)
      if (res.status) {
        new ToastTemplate(ToastType.Success, res.message).execute()

        setLoading(false)
        props.refreshGroupList()
      } else {
        new ToastTemplate(ToastType.Error, res.message).execute()

        setLoading(false)
      }
    } catch (err) {
      new ToastTemplate(ToastType.Error, err.message).execute()

      setLoading(false)
    }
  }

  async function editGroup(body) {
    try {
      setLoading(true)
      let res = await ManagementApi.groupApi.updateGroup.call(body)

      if (res.status) {
        new ToastTemplate(ToastType.Success, res.message).execute()

        props.refreshGroupList()
        setLoading(false)
      } else {
        new ToastTemplate(ToastType.Error, res.message).execute()

        setLoading(false)
      }
    } catch (err) {
      new ToastTemplate(ToastType.Error, err.message).execute()

      setLoading(false)
    }
  }
  async function handleGroup(data) {
    setInitialValues({
      groupNameField: data?.name || "",
      groupAccessLevel: data?.accessLevelIds || "",
    })
  }

  async function fillAccessLevel() {
    try {
      setLoading(true)
      let res = await ManagementApi.accessLevelsApi.accessLevelList.call()

      if (res.status) {
        setAccessLevelList(res.data)

        setLoading(false)
      } else {
        new ToastTemplate(ToastType.Error, res.message).execute()

        setLoading(false)
      }
    } catch (err) {
      new ToastTemplate(ToastType.Error, err.message).execute()

      setLoading(false)
    }
  }

  const MemoisedData = useMemo(() => {
    fillAccessLevel()
  }, [])
  useEffect(() => {
    if (props.mode === "edit") {
      handleGroup(props?.data)
    }
  }, [props])

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header  border-0">
              <h1
                className="modal-title fs-5 title-add-madal"
                id="exampleModalLabel"
              >
                {props.mode === "add" ? "Add Group" : "Edit Group"}
              </h1>
              <button
                type="button"
                className="btn-close"
                style={{ top: "18px", position: "absolute", right: "15px" }}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={GroupSchema}
                onSubmit={(values) => {
                  if (props.mode === "edit") {
                    let groupBody = {
                      name: values.groupNameField,
                      id: props?.data?.id,
                      accessLevelIds: values?.groupAccessLevel,
                    }
                    editGroup(groupBody)
                  } else {
                    let groupBody = {
                      name: values.groupNameField,
                      accessLevelIds: values?.groupAccessLevel,
                    }
                    addGroup(groupBody)
                  }
                }}
              >
                {({
                  values,
                  handleChange,
                  touched,
                  errors,
                  dirty,
                  isValid,
                  setFieldValue,
                }) => (
                  <Form className="d-flex align-items-center flex-column">
                    <div className="field-group">
                      <label
                        htmlFor="GroupName"
                        className="d-block ModelNumber-label"
                      >
                        Group name
                      </label>
                      <Field
                        className={
                          touched.groupNameField &&
                          errors.groupNameField != null
                            ? "form-control input-error error-margin Model-Number"
                            : "form-control Model-Number"
                        }
                        type="text"
                        name="groupNameField"
                        id={groupNameField.Name}
                      />
                      {touched.groupNameField &&
                      errors.groupNameField != null ? (
                        <div className="error">{errors.groupNameField}</div>
                      ) : null}
                    </div>

                    <div className="field-group ">
                      <label
                        className="mb-2"
                        htmlFor="individualAccessLevelIds"
                      >
                        group AccessLevels{" "}
                      </label>
                      <div className="combo-checkBox Model-Number h-100">
                        {accessLevelList.map((AC) => {
                          return (
                            <div className="form-check" key={AC.id}>
                              <Field
                                className={
                                  touched.groupAccessLevel &&
                                  errors.groupAccessLevel != null
                                    ? "form-check-input input-error error-margin  "
                                    : "form-check-input "
                                }
                                value={AC.id.toString()}
                                name={groupAccessLevel.Name}
                                type="checkbox"
                              />

                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                              >
                                {AC.name}
                              </label>
                            </div>
                          )
                        })}
                      </div>
                      {touched.groupAccessLevel &&
                      errors.groupAccessLevel != null ? (
                        <div className="error">{errors.groupAccessLevel}</div>
                      ) : null}
                    </div>
                    <div className="modal-footer border-0 d-flex justify-content-center">
                      <button
                        type="submit"
                        disabled={!(dirty && isValid)}
                        className={
                          !(dirty && isValid) ? "disabled-btn" : "add-modal-btn"
                        }
                        data-bs-dismiss="modal"
                      >
                        {props.mode === "add" ? "Add " : "Apply changes"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
