import React, { useState, useEffect, useRef } from "react"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from "jquery"
import { createRoot } from "react-dom/client"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import deleteIcon from "../../../Assets/icon/Delete.svg"
import { FiEdit } from "react-icons/fi"
import ManagementApi from "../../../Api/Managementapi"
import EmployeeModal from "../com/EmployeeModal"
import { ToastTemplate, ToastType } from "../../../notifications/ToastExecutor"
import LoadingScreen from "./../../../Component/LoadingScreen/LoadingScreen"
import LoginApi from "../../../Api/LoginApi"

export default function EmployeeTeble() {
  const table = useRef(null)
  const dataTableSearch = useState({})
  const [id, setId] = useState(null)
  const [rowId, setRowId] = useState(null)
  const [employeeBody, setEmployeeBody] = useState(null)
  const [loading, setLoading] = useState(false)
  const [employeePhoto, setEmployeePhoto] = useState("")
  const [groupList, setGroupList] = useState(
    ManagementApi.groupApi.groupList.default.data
  )

  const [accessLevelList, setAccessLevelList] = useState(
    ManagementApi.zoneApi.zoneList.default.data
  )

  async function loadPhotos(id) {
    try {
      setLoading(true)
      console.log(id)
      if (id.id) {
        let res = await ManagementApi.employeesApi.GetPhoto.call(id.id)

        if (res.status) {
          setEmployeePhoto("data:image/png;base64," + res.data)
        } else {
          new ToastTemplate(ToastType.Error, res.message).execute()
        }
      } else {
        setEmployeePhoto("data:image/png;base64," + id.photo)
      }
    } catch (err) {
      //new ToastTemplate(ToastType.Error, err.message).execute();
    }
    setLoading(false)
  }

  async function deleteEmployee(id, rowId) {
    try {
      setLoading(true)
      let res = await ManagementApi.employeesApi.deleteEmployees.call(id)

      if (res.status) {
        table.current.row(rowId.current).remove()
        table.current.draw()
        new ToastTemplate(ToastType.Success, res.message).execute()
      } else {
        new ToastTemplate(ToastType.Error, res.message).execute()
      }
    } catch (err) {
      new ToastTemplate(ToastType.Error, err.message).execute()
    }
    setLoading(false)
  }

  useEffect(() => {
    getGroup()
    fillAccessLevel()
  }, [])

  async function getGroup() {
    try {
      setLoading(true)
      let res = await ManagementApi.groupApi.groupList.call()

      if (res.status) {
        setGroupList(res.data)
      } else {
        new ToastTemplate(ToastType.Error, res.message).execute()
      }
    } catch (err) {
      new ToastTemplate(ToastType.Error, err.message).execute()
    }

    setLoading(false)
  }

  async function fillAccessLevel() {
    try {
      setLoading(true)
      let res = await ManagementApi.accessLevelsApi.accessLevelList.call()

      if (res.status) {
        setAccessLevelList(res.data)

        setLoading(false)
      } else {
        new ToastTemplate(ToastType.Error, res.message).execute()

        setLoading(false)
      }
    } catch (err) {
      new ToastTemplate(ToastType.Error, err.message).execute()

      setLoading(false)
    }
  }

  useEffect(() => {
    if (table.current) {
      table.current.destroy()
    }

    table.current = $("#table").DataTable({
      responsive: true,
      processing: true,
      serverSide: true,
      filter: true,
      ajax: {
        url: process.env.REACT_APP_API_URL + "/Employees/GetTablePage",
        type: "POST",
        beforeSend: function (xhr) {
          xhr.setRequestHeader("Authorization", LoginApi.getToken())
        },
        data: function (d) {
          // Add additional parameters for paging, sorting, and searching
          d.orderBy = d.columns[d.order[0].column].data // Column to order by
          d.orderDir = d.order[0].dir // Ordering direction
          d.searchText = d.search.value // Search text
          const columnSearch = d.columns
            .filter(
              (column) =>
                column.search.value && column.search.value.trim() !== ""
            )
            .reduce((acc, column) => {
              acc[column.data] = column.search.value.trim()
              return acc
            }, {})
          d.columnSearch = JSON.stringify(columnSearch)
          dataTableSearch.current = columnSearch
        },
        dataSrc: "data", // Property name in the response JSON containing the data array
        error: function (xhr, error, thrown) {
          console.log("Server-Paging", error)
        },
      },
      language: {
        paginate: {
          previous: "<",
          next: ">",
        },
      },
      lengthMenu: [
        [10, 15, 20, 30, 50],
        [10, 15, 20, 30, 50],
      ],
      order: [[0, "asc"]],
      pageLength: 10,

      columns: [
        {
          title: `UID`,
          data: "uid",
          searchable: true,
          orderable: true,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit")
          },
        },
        {
          title: `Group`,
          data: "groupName",
          searchable: true,
          orderable: true,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit")
          },
        },
        {
          title: `Full Name`,
          data: "fullName",
          searchable: true,
          orderable: true,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit")
          },
        },
        {
          title: `Card Serial`,
          data: "cardSerial",
          searchable: true,
          orderable: true,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit")
          },
        },
        {
          title: `Position`,
          data: "position",
          searchable: true,
          orderable: true,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit")
          },
        },

        {
          title: `Company`,
          data: "company",
          searchable: true,
          orderable: true,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit")
          },
        },

        {
          title: `Start Date`,
          data: "contractStartDate",
          searchable: false,
          orderable: true,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit")
          },
        },

        {
          title: `End Date`,
          data: "contractEndDate",
          searchable: false,
          orderable: true,
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit")
          },
        },

        {
          title: `Photo`,
          data: "photo",
          searchable: false,
          orderable: false,
        },

        {
          title: ``,
          searchable: false,
          orderable: false,
        },
      ],

      dom:
        "<'row  '<'col-sm-12 col-md-6 d-flex mt-3'f><'col-sm-6  flex-row-reverse col-md-6 d-flex flex-row-reverse mb-5'B>>" +
        "t<'row  e_table'<'col-sm-12 col-md-6 d-flex align-items-end justify-content-end 'p><'col-sm-6  flex-row-reverse col-md-6 d-flex flex-row-reverse   mt-3'l>>",

      columnDefs: [
        {
          target: [-2],
          createdCell: (td, cellData, rowData, row, col) => {
            createRoot(td).render(
              <div className="d-flex align-items-center justify-content-start">
                <button
                  className="viewBtn "
                  data-bs-toggle="modal"
                  data-bs-target="#showPhotoModal"
                  onClick={() => {
                    loadPhotos(rowData)
                  }}
                >
                  View
                </button>
              </div>
            )
          },
        },

        {
          target: -1,
          createdCell: (td, cellData, rowData, row, col) => {
            const employee = rowData
            createRoot(td).render(
              <div className="d-flex align-items-center justify-content-around">
                <button
                  className="deleteBtn "
                  data-bs-toggle="modal"
                  data-bs-target="#deleteModal"
                  onClick={() => {
                    setId(employee.id)
                    setRowId(row)
                  }}
                >
                  <img src={deleteIcon} alt="" />
                </button>
                <button
                  className="deleteBtn "
                  data-bs-toggle="modal"
                  data-bs-target="#editEmployeeModal"
                  onClick={() => {
                    setEmployeeBody(employee)
                    setRowId(row)
                  }}
                >
                  <FiEdit />
                </button>
              </div>
            )
          },
        },

        {
          defaultContent: "-",
          targets: ["_all"],
          className: "dt-body-left",
        },
      ],
      buttons: [
        {
          extend: "excelHtml5",
          text: '<i class="fa-solid fa-file-excel"></i>',
          className: "btn-report fs-4",
          action: function (e, dt, button, config) {
            setLoading(true)
            const searchText = table.current.search()
            const columns = dt.settings().init().columns
            const columnSearch = dataTableSearch.current
            const columnNames = columns.map((column) => column.data)

            // Make a request to your ASP.NET Core Web API to export the data
            fetch(process.env.REACT_APP_API_URL + `/Employees/Export`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: LoginApi.getToken(),
              },
              body: JSON.stringify({
                searchText,
                columnNames,
                columnSearch,
              }),
            })
              .then((response) => response.blob())
              .then((blob) => {
                // Create a download link for the Excel file
                const url = URL.createObjectURL(blob)
                const link = document.createElement("a")
                link.href = url
                link.download = "Employees.xlsx"

                // Trigger the download
                link.click()

                // Clean up the temporary URL
                setTimeout(function () {
                  URL.revokeObjectURL(url)
                }, 100)
              })
              .catch((error) => {
                console.error(error)
              })
            setLoading(false)
          },
        },
        {
          text: '<i class="fa-solid fa-rotate-right fs-4"></i>',
          className: "re-btn ",
          action: function (e, dt, node, config) {
            dt.ajax.reload()
          },
        },
      ],
      initComplete: function () {
        $("#table thead tr")
          .removeClass("col-edit")
          .addClass("filters")
          .appendTo("#table thead")

        var api = this.api()
        // For each column
        api
          .columns()
          .eq(0)
          .each(function (colIdx) {
            var column = api.settings().init().columns[colIdx]
            var cell = $(".filters th").eq(
              $(api.column(colIdx).header()).index()
            )
            var title = column.title
            if (!column.searchable) {
              $(cell).html(`
                <div style="display: flex; align-items: center;">
                  <span class="col-edit">${title}</span>
                </div>
              `)
            } else {
              // If the column is searchable, add the search input
              // Set the header cell to contain the input element
              $(cell).html(`
              <div style="display: flex; align-items: center;">
                <span class="col-edit">${title}</span>
                <img src="/search.svg" alt="search" style="cursor: pointer; margin-left: auto;" />
              </div>
              <div class="hide clear-input-container" >
                <input type="text" placeholder="search" class="clear-input" />
                <button class="clear-input-button">X</button>
              </div>
              `)

              // On clicking the search icon, toggle the visibility of the input element
              $("img", cell).on("click", function (e) {
                e.preventDefault()
                e.stopPropagation()
                $(".clear-input-container", cell).toggleClass("hide")
              })

              $("input", cell).on("input", function (e) {
                const input = $(this)
                if (input.val() && !input.hasClass("clear-input--touched")) {
                  input.addClass("clear-input--touched")
                } else if (
                  !input.val() &&
                  input.hasClass("clear-input--touched")
                ) {
                  input.removeClass("clear-input--touched")
                }
              })
              $("button", cell).on("click", function (e) {
                e.stopPropagation()
                const input = $(this).siblings(".clear-input")
                input.val("")
                input.removeClass("clear-input--touched")
                input.trigger("change")
                $(".clear-input-container", cell).toggleClass("hide")
              })
              // On every keypress in this input
              $("input", cell)
                .off("keyup change")
                .on("change", function (e) {
                  // Get the search value
                  $(this).attr("title", $(this).val())
                  api.columns(colIdx).search(this.value).draw()
                })
                .on("keydown", function (e) {
                  e.stopPropagation()
                })
                .on("keyup", function (e) {
                  e.stopPropagation()
                  $(this).trigger("change")
                })
                .on("click", function (e) {
                  e.preventDefault() // Prevent the default behavior (sorting action)
                  e.stopPropagation() // Stop event propagation to avoid unintended side effects
                })
            }
          })
      },
    })
  })

  function addEmployee(employee) {
    table.current.ajax.reload()
  }

  function updateEmployee(employee, rowId) {
    table.current.ajax.reload()
  }

  return (
    <>
      {loading ? <LoadingScreen /> : <></>}
      <div className="card-table">
        <table
          id="table"
          className="display   row-border "
          style={{ width: "100%", marginTop: "40px" }}
        ></table>
      </div>
      {/*st --- photo view modal */}
      <div
        className="modal fade"
        id="showPhotoModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header  border-0">
              <button
                type="button"
                className="btn-close"
                style={{
                  top: "18px",
                  position: "absolute",
                  right: "15px",
                }}
                onClick={() => setEmployeePhoto("")}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body d-flex align-items-center justify-content-center">
              <img src={employeePhoto} width={300} height={300} alt="" />
            </div>
          </div>
        </div>
      </div>
      {/*en --- photo view modal */}

      <div
        className="modal fade"
        id="editEmployeeModal"
        tabIndex="-1"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <EmployeeModal
          mode={"edit"}
          updateEmployee={updateEmployee}
          photoId={"editEmployeeModalPhoto"}
          data={employeeBody}
          groupList={groupList}
          accessLevelList={accessLevelList}
          rowId={rowId}
        />
      </div>

      <div
        className="modal fade"
        id="addEmployeeModal"
        tabIndex="-1"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <EmployeeModal
          photoId={"addEmployeeModalPhoto"}
          addEmployee={addEmployee}
          groupList={groupList}
          accessLevelList={accessLevelList}
          mode={"add"}
        />
      </div>

      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body d-flex align-items-center justify-content-center">
              <h1>Are you sure you want to delete?</h1>
            </div>
            <div className="modal-footer border-0 d-flex justify-content-center">
              <button
                type="submit"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => {
                  deleteEmployee(id, rowId)
                }}
              >
                YES
              </button>
              <button
                type="submit"
                className="btn  btn-success"
                data-bs-dismiss="modal"
              >
                NO
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
