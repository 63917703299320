import React, { useCallback, useRef } from "react"
import { useState, useEffect } from "react"
import { Formik, Field, Form } from "formik"
import { getInitialValues } from "../../../extensions/FormikExtension"
import { useMemo } from "react"
import {
  ToastTemplate,
  ToastType,
} from "./../../../notifications/ToastExecutor"
import ManagementApi from "../../../Api/Managementapi"
import LoadingScreen from "./../../../Component/LoadingScreen/LoadingScreen"
import {
  EmployeeSchema,
  uid,
  fullName,
  position,
  company,
  contractStartDate,
  contractEndDate,
  photo,
  groupId,
  cardSerial,
  individualAccessLevelIds,
} from "../../../validationSchema/EmployeeSchema"

export default function EmployeeModal(props) {
  const [imageBase64, setImageBase64] = useState("")
  const [uploadPhotoMode, setUploadPhotoMode] = useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState(
    getInitialValues([
      uid,
      fullName,
      position,
      company,
      contractStartDate,
      contractEndDate,
      photo,
      groupId,
      cardSerial,
      individualAccessLevelIds,
    ])
  )

  const formikRef = useRef()

  const [loading, setLoading] = useState(false)

  async function resizeAndConvertImage(imageFile) {
    return new Promise((resolve, reject) => {
      const img = new Image()

      img.onload = () => {
        const canvas = document.createElement("canvas")
        const ctx = canvas.getContext("2d")

        // Set the maximum width and height for the photo
        const maxWidth = 200
        const maxHeight = 200

        // Calculate the new dimensions while maintaining the aspect ratio
        let newWidth, newHeight
        if (img.width > img.height) {
          newWidth = maxWidth
          newHeight = (img.height / img.width) * maxWidth
        } else {
          newWidth = (img.width / img.height) * maxHeight
          newHeight = maxHeight
        }

        canvas.width = newWidth
        canvas.height = newHeight

        // Draw the image on the canvas with the new dimensions
        ctx.drawImage(img, 0, 0, newWidth, newHeight)

        // Convert the canvas content to a data URL
        const dataURL = canvas.toDataURL("image/jpeg")

        // Convert the data URL to a File object
        const byteString = atob(dataURL.split(",")[1])
        const ab = new ArrayBuffer(byteString.length)
        const ia = new Uint8Array(ab)
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i)
        }
        const file = new File([ab], "resized.jpg", { type: "image/jpeg" })

        resolve(file)
      }

      img.onerror = reject

      // Set the image source directly from the file
      img.src = URL.createObjectURL(imageFile)
    })
  }

  async function handlePhoto(e, setFieldValue) {
    let file = e.target.files[0]
    if (file === undefined) return

    file = await resizeAndConvertImage(file)

    const reader = new FileReader()
    reader.onloadend = () => {
      setImageBase64(reader.result)
      setFieldValue("photo", reader.result)
    }
    reader.readAsDataURL(file)
  }
  function handleFormikFields(data) {
    setSelectedEmployee({
      id: data?.id || "",
      uid: data?.uid || "",
      fullName: data?.fullName || "",
      position: data?.position || "",
      company: data?.company || "",
      cardSerial: data?.cardSerial || "",
      contractStartDate:
        data?.contractStartDate?.replace("T00:00:00", "") || "",
      contractEndDate: data?.contractEndDate?.replace("T00:00:00", "") || "",
      photo: data?.photo?.substr(data?.photo?.indexOf(",") + 1) || "",
      groupId: data?.groupId || "",
      individualAccessLevelIds: data?.individualAccessLevelIds || "",
    })
  }

  const fetchEmployeeInfo = useCallback(async (data) => {
    if (data && data.id != null) {
      setLoading(true)
      if (!data.photo || data?.photo.trim() === "") {
        try {
          setLoading(true)
          let result = await ManagementApi.employeesApi.GetPhoto.call(data.id)
          if (result.status && result.data && result.data.trim() !== "")
            data.photo = "data:image/png;base64," + result.data
          else data.photo = ""
        } catch (err) {}
      }
      handleFormikFields(data)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchEmployeeInfo(selectedEmployee)
    if (props.mode === "edit") {
      fetchEmployeeInfo(props?.data)
    }
  }, [props])

  async function addEmployee(employeeBody) {
    setLoading(true)

    try {
      let res = await ManagementApi.employeesApi.addEmployees.call(employeeBody)
      if (res.status) {
        new ToastTemplate(ToastType.Success, res.message).execute()
        setLoading(false)
        res.data.groupName = props.groupList.filter(
          (g) => g.id == res.data.groupId
        )[0].name
        props.addEmployee(res.data)
      } else {
        new ToastTemplate(ToastType.Error, res.message).execute()
        setLoading(false)
      }
    } catch (err) {
      new ToastTemplate(
        ToastType.Error,
        err.message === undefined ? "Oops! Something went wrong" : err.message
      ).execute()
      setLoading(false)
    }
  }

  async function editEmployee(employeeBody) {
    try {
      setLoading(true)
      let res = await ManagementApi.employeesApi.updateEmployees.call(
        employeeBody
      )

      if (res.status) {
        new ToastTemplate(ToastType.Success, res.message).execute()

        setLoading(false)
        employeeBody.groupName = props.groupList.filter(
          (g) => g.id == employeeBody.groupId
        )[0].name
        props.updateEmployee(employeeBody, props.rowId)
      } else {
        new ToastTemplate(ToastType.Error, res.message).execute()

        setLoading(false)
      }
    } catch (err) {
      new ToastTemplate(ToastType.Error, err.message).execute()

      setLoading(false)
    }
  }

  return (
    <>
      {loading ? <LoadingScreen /> : ""}
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header  border-0">
            <h1
              className="modal-title fs-5 title-add-madal mb-0"
              id="exampleModalLabel"
            >
              {props.mode === "add" ? "Add Employee" : "Edit Employee"}
            </h1>
            <button
              style={{
                top: "18px",
                position: "absolute",
                right: "15px",
              }}
              onClick={() => {
                formikRef.current?.resetForm()
                setImageBase64("")
              }}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <Formik
              ref={formikRef}
              enableReinitialize={true}
              initialValues={selectedEmployee}
              validationSchema={EmployeeSchema}
              onSubmit={(values, { resetForm }) => {
                if (props.mode === "edit") {
                  let employeeBody = {
                    uid: values.uid,
                    fullName: values.fullName,
                    position: values.position,
                    company: values.company,
                    cardSerial: values.cardSerial,
                    contractStartDate: values.contractStartDate,
                    contractEndDate: values.contractEndDate,
                    photo: values.photo.substr(values.photo.indexOf(",") + 1),
                    groupId: values.groupId,
                    individualAccessLevelIds: values.individualAccessLevelIds,
                    id: props.data.id,
                    extras: props.data.extras,
                  }

                  editEmployee(employeeBody)
                  resetForm()
                  setImageBase64("")
                  console.log(employeeBody)
                } else {
                  let addBody = {
                    uid: values.uid,
                    fullName: values.fullName,
                    position: values.position,
                    company: values.company,
                    cardSerial: values.cardSerial,
                    contractStartDate: values.contractStartDate,
                    contractEndDate: values.contractEndDate,
                    photo: values.photo.substr(values.photo.indexOf(",") + 1),
                    groupId: values.groupId,
                    groupName: props.groupList.filter(
                      (g) => g.id == values.groupId
                    )[0].name,
                    individualAccessLevelIds: values.individualAccessLevelIds,
                    extras: "",
                  }
                  addEmployee(addBody)
                  resetForm()
                  setImageBase64("")
                }
              }}
            >
              {({
                values,
                handleChange,
                touched,
                errors,
                dirty,
                isValid,
                setFieldValue,
              }) => (
                <Form className="d-flex align-items-center flex-column">
                  <div className="row d-flex align-items-center justify-content-between w-100">
                    <div className="col-md-6">
                      <div className="field-group w-100 mb-2">
                        <label htmlFor="uid">Unique ID</label>
                        <Field
                          className={
                            touched.uid && errors.uid != null
                              ? "form-control input-error error-margin "
                              : "form-control "
                          }
                          type="text"
                          name="uid"
                          id={uid.Name}
                        />
                        {touched.uid && errors.uid != null ? (
                          <div className="error">{errors.uid}</div>
                        ) : null}
                      </div>
                      <div className="field-group w-100 mb-2">
                        <label htmlFor="fullName">Full name</label>
                        <Field
                          className={
                            touched.fullName && errors.fullName != null
                              ? "form-control input-error error-margin "
                              : "form-control "
                          }
                          type="text"
                          name="fullName"
                          id={fullName.Name}
                        />
                        {touched.fullName && errors.fullName != null ? (
                          <div className="error">{errors.fullName}</div>
                        ) : null}
                      </div>
                      <div className="field-group w-100 mb-2">
                        <label htmlFor="position">Position</label>
                        <Field
                          className={
                            touched.position && errors.position != null
                              ? "form-control input-error error-margin "
                              : "form-control "
                          }
                          type="text"
                          name="position"
                          id={position.Name}
                        />
                        {touched.position && errors.position != null ? (
                          <div className="error">{errors.position}</div>
                        ) : null}
                      </div>
                      <div className="field-group w-100 mb-2">
                        <label htmlFor="company">Company</label>
                        <Field
                          className={
                            touched.company && errors.company != null
                              ? "form-control input-error error-margin "
                              : "form-control "
                          }
                          type="text"
                          name="company"
                          id={company.Name}
                        />
                        {touched.company && errors.company != null ? (
                          <div className="error">{errors.company}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-end">
                      <div className="profile-pic-div">
                        {props?.mode === "edit" && uploadPhotoMode === false ? (
                          <img
                            src={
                              "data:image/*;base64," + selectedEmployee?.photo
                            }
                            width="100%"
                            height="100%"
                          />
                        ) : (
                          <img
                            src={
                              imageBase64.length === 0
                                ? "data:image/*;base64," +
                                  selectedEmployee?.photo
                                : imageBase64
                            }
                            width="100%"
                            height="100%"
                          />
                        )}

                        {/* <img src={props?.mode === "edit" && uploadPhotoMode === false ? "data:image/*;base64," + initialValues?.photo : imageBase64} width="100%" height="100%" /> */}
                        <input
                          type="file"
                          className="uploadPhoto"
                          accept="image/*"
                          onChange={(e) => {
                            handlePhoto(e, setFieldValue)
                            setUploadPhotoMode(true)
                          }}
                        />
                        <label htmlFor="file" className="upload-photo-label">
                          upload Photo
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="field-group w-100 mb-2">
                          <label htmlFor="cardSerial">Card serial</label>
                          <Field
                            className={
                              touched.cardSerial && errors.cardSerial != null
                                ? "form-control input-error error-margin "
                                : "form-control "
                            }
                            type="text"
                            name="cardSerial"
                            id={cardSerial.Name}
                          />
                          {touched.cardSerial && errors.cardSerial != null ? (
                            <div className="error">{errors.cardSerial}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="field-group mb-2 w-100">
                          <label htmlFor="contractStartDate">
                            Contract Start Date
                          </label>
                          <Field
                            className={
                              touched.contractStartDate &&
                              errors.contractStartDate != null
                                ? "form-control input-error error-margin "
                                : "form-control "
                            }
                            type="date"
                            name="contractStartDate"
                            id={contractStartDate.Name}
                          />
                          {touched.contractStartDate &&
                          errors.contractStartDate != null ? (
                            <div className="error">
                              {errors.contractStartDate}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="field-group mb-2 w-100">
                          <label htmlFor="contractEndDate">
                            Contract End Date
                          </label>
                          <Field
                            className={
                              touched.contractEndDate &&
                              errors.contractEndDate != null
                                ? "form-control input-error error-margin "
                                : "form-control "
                            }
                            type="date"
                            name="contractEndDate"
                            id={contractEndDate.Name}
                          />
                          {touched.contractEndDate &&
                          errors.contractEndDate != null ? (
                            <div className="error">
                              {errors.contractEndDate}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          {values.groupId === "" ? (
                            <div className="col-md-12 transation">
                              <div className="field-group mb-2 w-100">
                                <label htmlFor="groupId"> Group id</label>
                                <Field
                                  as="select"
                                  className={
                                    touched.groupId && errors.groupId != null
                                      ? "form-select input-error error-margin "
                                      : "form-select "
                                  }
                                  name="groupId"
                                  id={groupId.Name}
                                >
                                  <option value="">Select Group</option>
                                  {props.groupList.map((group) => {
                                    return (
                                      <option key={group.id} value={group.id}>
                                        {group.name}
                                      </option>
                                    )
                                  })}
                                </Field>

                                {touched.groupId && errors.groupId != null ? (
                                  <div className="error">{errors.groupId}</div>
                                ) : null}
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="col-md-6 transation">
                                <div className="field-group mb-2 w-100">
                                  <label htmlFor="groupId"> Group id</label>
                                  <Field
                                    as="select"
                                    className={
                                      touched.groupId && errors.groupId != null
                                        ? "form-select input-error error-margin "
                                        : "form-select "
                                    }
                                    name="groupId"
                                    id={groupId.Name}
                                  >
                                    <option value="">Select Group</option>
                                    {props.groupList.map((group) => {
                                      return (
                                        <option key={group.id} value={group.id}>
                                          {group.name}
                                        </option>
                                      )
                                    })}
                                  </Field>

                                  {touched.groupId && errors.groupId != null ? (
                                    <div className="error">
                                      {errors.groupId}
                                    </div>
                                  ) : null}
                                </div>
                              </div>

                              <div className="col-md-6 transation">
                                <label htmlFor="">Group access level</label>
                                <div className="combo-checkBox d-flex align-items-center justify-content-center flex-column">
                                  {props.groupList
                                    .filter((item) => {
                                      return values.groupId === item.id
                                        ? item
                                        : ""
                                    })
                                    .map((item) => {
                                      return (
                                        <div key={item.id}>
                                          {item.accessLevels.map((a) => {
                                            return (
                                              <li key={a.name}>{a.name}</li>
                                            )
                                          })}
                                        </div>
                                      )
                                    })}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="field-group w-100">
                            <label htmlFor="individualAccessLevelIds">
                              individual AccessLevel Ids
                            </label>
                            <div className="combo-checkBox">
                              {props.accessLevelList.map((AC) => {
                                return (
                                  <div key={AC.id} className="form-check">
                                    <Field
                                      className={
                                        touched.individualAccessLevelIds &&
                                        errors.individualAccessLevelIds != null
                                          ? "form-check-input input-error error-margin "
                                          : "form-check-input "
                                      }
                                      value={AC.id.toString()}
                                      name={individualAccessLevelIds.Name}
                                      type="checkbox"
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckDefault"
                                    >
                                      {AC.name}
                                    </label>
                                  </div>
                                )
                              })}
                            </div>
                            {touched.individualAccessLevelIds &&
                            errors.individualAccessLevelIds != null ? (
                              <div className="error">
                                {errors.individualAccessLevelIds}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer border-0 d-flex justify-content-center">
                    <button
                      type="submit"
                      className={
                        !(dirty && isValid) ? "disabled-btn" : "add-modal-btn"
                      }
                      data-bs-dismiss="modal"
                      disabled={!(dirty && isValid)}
                    >
                      {props?.mode === "edit" ? "Apply changes" : "Add"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}
